
import Picture from "@/components-ui/Picture/Picture.vue";

export default {
  name: "Definition",
  components: {
    Picture,
  },
  props: {
    index: Number,
    definition: Object,
  },
  data() {
    return {
      isDescriptionShown: false,
      imageLoaded: false,
    };
  },
  computed: {
    isOpen() {
      return this.isDescriptionShown && "open";
    },
    buttonState() {
      return this.isDescriptionShown ? "toggleDescription close" : "toggleDescription";
    },
    buttonActionLabel() {
      return this.isDescriptionShown ? "Fermer la description" : "Ouvrir la description";
    },
  },
};
